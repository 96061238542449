import React from 'react'
import about from '../images/about.png'

export function VzuuFooter() {
  return (
    <footer>
      <p className="credits">
        ©2020 V族网 北京雷盟盛通文化发展有限公司 010-82716601
        北京市海淀区学清路18号6幢2层6222室
      </p>
      <p className="credits">
        <a href="https://zhstatic.zhihu.com/vzuu/9bd6e767435361db875729bd6e76722.jpg">
          信息网络传播视听节目许可证 证号 0110562
        </a>

        <a href="https://beian.miit.gov.cn/#/Integrated/index">
          京ICP备19038345号-1
        </a>

        <a href="https://tsm.miit.gov.cn/dxxzsp/">京ICP证 京B2-0201838号</a>

        <a href="https://pica.zhimg.com/v2-5e84c274737c0b20b702a6ea8dba9aa2.png">
          广播电视节目制作经营许可证:（京）字第09685号
        </a>

        <a href="https://picx.zhimg.com/v2-f2ac815791a7c15c400ac32d18fc45a5.png">
          京网文[2022]1421-047号
        </a>
      </p>
    </footer>
  )
}

export default () => (
  <div className="title-page VideoPage about-page">
    <h2 className="section-title">关于我们</h2>
    <img className="our-pic" src={about} />
    <p className="title-description">
      V
      族网由北京雷盟盛通文化发展有限公司创建并运营，旨在为广大网友提供文艺、娱乐等专业类视听服务。自成立以来，公司不断响应行业以及用户诉求，坚持技术创新，满足用户日益增长的互动需求和体验，积极和业内知名视频网站开展合作，以健康、轻松、积极向上的表现形式投拍、制作视听作品，充分利用公司在文化与时尚方面的资源，将文化与时尚紧密结合，打造有价值的文化时尚传媒品牌。近年来，V族网进一步提升互联网传播能力，切入共享知识经济的领域，通过丰富多彩、寓教于乐的网络视听节目满足用户对专业化和垂直化等优质内容的认知需求，努力降低认知壁垒和学习成本，为中文互联网提供源源不断的高质量的知识类趣味性信息。
    </p>
    <span className="contact">客服QQ：495715660</span>
  </div>
)
