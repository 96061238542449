import PropTypes from 'prop-types'
import React, {Component} from 'react'
import cx from 'classnames'
import range from 'lodash/range'
import PaginationButton from './PaginationButton'
import './index.css'

export default class Pagination extends Component {
  static propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    clickCallback: PropTypes.func,
  }

  static defaultProps = {
    current: 1,
    total: 1,
    clickCallback: () => {},
  }

  static getPagerList(current, total) {
    if (total < 6) {
      return range(1, total + 1)
    } else if (current >= total - 2) {
      return [1, '...'].concat(range(total - 3, total + 1))
    } else if (current <= 3) {
      return range(1, 5).concat(['...', total])
    }
    return [1, '...'].concat(range(current - 1, current)).concat(['...', total])
  }

  render() {
    const {current, total, clickCallback, className} = this.props
    const pagerList = this.constructor.getPagerList(current, total)

    return (
      <div className={cx('Pagination', className)}>
        {current !== 1 && total !== 1 && (
          <PaginationButton
            className="PaginationButton-prev"
            clickCallback={clickCallback}
            text=""
            page={current - 1}
          />
        )}
        {pagerList.map((page, index) =>
          Number(page) ? (
            <PaginationButton
              clickCallback={clickCallback}
              text={page}
              page={page}
              key={index}
              isCurrent={current === page}
            />
          ) : (
            <span
              key={index}
              className="PaginationButton PaginationButton--fake"
            >
              {page}
            </span>
          )
        )}
        {current !== total && (
          <PaginationButton
            className="PaginationButton-next"
            clickCallback={clickCallback}
            text=""
            page={current + 1}
          />
        )}
        <span>共{` ${total} `}页</span>
      </div>
    )
  }
}
