import React from 'react'
import {Route, IndexRoute, browserHistory, Router} from 'react-router'
import Index from './pages/Index'
import Home from './pages/Home'
import Qualification from './pages/Qualification'
import Certificate from './pages/Certificate'

import './App.css'

const App = () => (
  <div className="App">
    <Router history={browserHistory}>
      <Route path="/" component={Index}>
        <IndexRoute component={Home} />
        {/* 目前用于通过 v.vzuu.com/77b7dd77b15e87f0942832b92e0eff2c 来 mock vzuu.com 首页  */}
        <Route path="/77b7dd77b15e87f0942832b92e0eff2c" component={Home} />
        <Route path="/qualification" component={Qualification} />
        <Route path="/certificate" component={Certificate} />
      </Route>
    </Router>
  </div>
)

export default App
