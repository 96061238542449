import React, {Component} from 'react'
import './DashBoard.css'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

const DashBoardItemView = ({name, value}) => (
  <div className="dashboard-item">
    <div className="dashboard-content">
      <p className="dashboard-title">{name}</p>
      <p className="dashboard-count">{value}</p>
    </div>
  </div>
)

const DashBoardData = () => {
  // 模拟随机数
  const base = new Date('2020-06-01').getTime()
  const now = new Date()
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const percent = (now - today) / (1000 * 60 * 60 * 24)

  const newVideoCount = parseInt(percent * 75)
  const nowVideoCount =
    5000 + parseInt(((now - base) / 1000 / 60 / 60 / 24) * 50) + newVideoCount
  const playCount = parseInt(percent * 100000)
  const onlineNumber = getRandomInt(1000, 1700)
  return [
    <DashBoardItemView key={1} name="当日播放总量" value={playCount} />,
    <DashBoardItemView key={2} name="当日新增视频" value={newVideoCount} />,
    <DashBoardItemView key={3} name="当日在线人数" value={onlineNumber} />,
    <DashBoardItemView key={4} name="当前视频总量" value={nowVideoCount} />,
  ]
}

export default class Dashboard extends Component {
  render() {
    return (
      <div className="dashboard">
        <h2 className="title">今日数据</h2>
        <div className="dashboard-list">
          <DashBoardData render={DashBoardItemView} />
        </div>
      </div>
    )
  }
}
