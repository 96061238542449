import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './popover.css'

class Popover extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
  }

  static defaultProps = {
    show: false,
  }
  constructor(props) {
    super(props)
    this.state = {
      visible: props.show,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {show} = nextProps
    if (show !== this.state.visible) {
      this.setState({
        visible: show,
      })
    }
  }

  go2Page = url => {
    window.location.href = url
  }

  toggleEnterPopover = () => {
    this.setState({visible: true})
  }

  toggleLeavePopover = () => {
    this.setState({visible: false})
  }

  render() {
    const {visible} = this.state
    const {list} = this.props
    return (
      <div
        className={cx('Popover--mask', {
          'Popover-unshow': !visible,
        })}
      >
        <div
          className="Popover--wrapper"
          onMouseEnter={this.toggleEnterPopover}
          onMouseLeave={this.toggleLeavePopover}
        >
          <ul>
            {list.map(option => (
              <li
                className="Popover-list-item"
                key={option.name}
                onClick={this.go2Page.bind(this, option.url)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default Popover
