export const LOCAL_PREFIX_NAME = 'vzuu-cookie'
export const NEXT_HOST = 'https://v.vzuu.com'

export const NAVS = [
  {name: '首页', id: 0},
  {name: '生活', id: 1},
  // {name: '新知（收费）', id: 2},
  {name: '人文', id: 3},
  {name: '娱乐', id: 4},
  // {name: '职人介绍所', id: 5},
  // {name: '直播', id: 7},
  {name: 'V族汇', id: 8},
  {name: '关于我们', id: 6},
]

export const UPLOAD_OPTIONS = [
  {
    name: '文件上传',
    url: `${NEXT_HOST}/vz/upload-video`,
  },
  {
    name: '添加链接',
    url: `${NEXT_HOST}/vz/add-video-link`,
  },
]
