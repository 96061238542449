import React, {Component, Fragment} from 'react'
import cx from 'classnames'
import videoList from '../config/video_list.json'
import {
  Videos,
  CardList,
  HappyList,
  DashBoard,
  About,
  VzuuFooter,
  Popover,
} from '../components'
import banner from '../images/v-banner-2020-6-16.jpg'
import logo from '../images/v-logo.png'
import {
  NAVS as navs,
  NEXT_HOST,
  LOCAL_PREFIX_NAME,
  UPLOAD_OPTIONS,
} from '../const'
import {getCookie, setCookie} from '../cookie'
import './Home.css'

export default class HomePage extends Component {
  state = {
    curId: 0,
    videoSource: videoList,
    isLogin: false,
    username: '',
    isHover: false,
    popover: false,
  }
  componentDidMount() {
    const userId = getCookie(`${LOCAL_PREFIX_NAME}-user_id`)
    const overTime = getCookie(`${LOCAL_PREFIX_NAME}-timeout`)
    if (!userId) {
      return
    } else if (Math.abs(overTime - Date.now()) < 60 * 60 * 1000) {
      this.setState({
        isLogin: true,
        username: userId,
      })
    } else {
      setCookie(`${LOCAL_PREFIX_NAME}-user_id`, '')
      setCookie(`${LOCAL_PREFIX_NAME}-timeout`, '')
      setCookie(`${LOCAL_PREFIX_NAME}-z_c0`, '')
    }
  }

  get curTabName() {
    const {curId} = this.state
    return navs.filter(item => item.id === curId)[0].name
  }

  handleTab = item => {
    window.scrollTo(0, 0)
    this.setState({curId: item.id})
  }

  handleLogin = () => {
    const {isLogin} = this.state
    if (isLogin) {
      setCookie(`${LOCAL_PREFIX_NAME}-user_id`, '')
      setCookie(`${LOCAL_PREFIX_NAME}-timeout`, '')
      setCookie(`${LOCAL_PREFIX_NAME}-z_c0`, '')
      this.setState({
        isLogin: false,
        username: '',
      })
    } else {
      window.location.href = `${NEXT_HOST}/vz/signin`
    }
  }

  renderContent = () => {
    const {videoSource, curId} = this.state
    switch (curId) {
      case 0:
        return (
          <Fragment>
            <CardList />
            <HappyList videoSource={videoSource} handleTab={this.handleTab} />
          </Fragment>
        )
      case 6:
        return <About />
      case 7:
        window.location.href = `${NEXT_HOST}/vz/theater-list`
        return
      case 8:
        window.location.href = `${NEXT_HOST}/vz/user/video`
        return
      case 12:
        window.location.href = `${NEXT_HOST}/vz/signin`
        return
      default:
        return (
          <Videos
            title={this.curTabName}
            id={curId}
            videoSource={videoSource}
          />
        )
    }
  }

  toggleHover = () => {
    this.setState({
      isHover: !this.state.isHover,
    })
  }

  togglePopover = () => {
    this.setState({
      popover: !this.state.popover,
    })
  }

  render() {
    const {curId, isLogin, username, isHover, popover} = this.state

    const loginTxt = isLogin
      ? isHover
        ? '退出登录'
        : `用户 ${username}`
      : '登录'
    return (
      <div className="Home">
        <a href="http://www.71.cn" target="_blank" rel="noopener noreferrer">
          <img className="top-pic" src={banner} />
        </a>
        <div className="Nav-wrapper">
          <nav className="Nav">
            <img className="icon-pic" src={logo} />
            <span className="nav-text">V 族网</span>
            <ul>
              {navs.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      className={cx('Nav-item', {active: curId === item.id})}
                      onClick={() => {
                        // 「V 族汇」直接跳转，不激活 Tab
                        if (item.id !== 8) {
                          this.setState({curId: item.id})
                        } else {
                          window.location.href = `${NEXT_HOST}/vz/user/video`
                        }
                      }}
                    >
                      {item.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
        {this.renderContent()}
        {curId !== 8 && <DashBoard />}
        <VzuuFooter />
        <div className="button-group-nav">
          <div className="button-group">
            <div
              className="button-item"
              onClick={() => {
                this.setState({
                  popover: true,
                })
              }}
              onMouseEnter={this.togglePopover}
              onMouseLeave={this.togglePopover}
            >
              上传视频
              <Popover show={popover} list={UPLOAD_OPTIONS} />
            </div>
            <div
              className={cx('button-item', {isLogin: isLogin})}
              onClick={this.handleLogin}
              onMouseEnter={this.toggleHover}
              onMouseLeave={this.toggleHover}
            >
              {loginTxt}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
