import React, {Component} from 'react'

import './CardList.css'
import card1 from '../images/card1.png'
// import card2 from '../images/card2.jpg'
import card3 from '../images/card3.jpg'
// import playIcon from '../images/play-button.png'

const VAL_PRE_TITLE = '自制节目'
const data = [
  {
    title: '知乎者耶',
    desc: '如何看待一些20多岁的年轻人「中年危机」提前了',
    content:
      '很多年轻人常常感觉自己的「中年危机」提前来了：非常在意自己的发际线、外出活动开始带保温杯、计划攒钱买各种保险、带着防霾口罩也得去健身、想和更年轻的朋友玩耍、甚至也打算去参加马拉松了，但是为了证明体力依然在线……本期视频中，知友针对这个问题分享了自己的观点。',
    pic: card1,
    src: 'https://video.zhihu.com/video/1053697117134135296',
    link: 'https://zhuanlan.zhihu.com/p/37709620',
  },
  {
    preFixTitle: '视听节目',
    title: '追凶神探',
    desc: '网呼车',
    content:
      '网络剧《追凶神探》讲述了龙安市发生一起网约车司机杀人案件，特调处队长林秋浦带队调查此案。初入特调组林冬雪在做走访工作时，上了嫌疑人网约车司机陈实的车。林冬雪带陈实回去调查，发现陈实并非凶手，陈实帮助林冬雪查案，发现网约车司机杀人另有隐情……',
    pic: card1,
    src: 'https://video.zhihu.com/video/1391732891710185472',
    link: 'https://www.zhihu.com/zvideo/1393158443440611328',
  },
  {
    title: '异常时区 2.0',
    preFixTitle: '视听节目',
    desc: '闭环',
    content:
      '《异常时区 2.0》讲述了脑洞大开又辛辣讽刺的未来职场寓言：饱受脱发困扰的职场新人，竟成为公司利润的回收田；为了免费美白不停参加优惠活动的女生，竟成为黑心资本闭环的受害者；相互 PK 贡献与福报，争取到公司「诺亚方舟」最后名额的打工人，竟走向了更恐怖的悲剧……这一切看似离我们十分遥远的奇幻故事，其实就真实发生在我们身边，在这个「异常时区」中轮番上演。',
    pic: card1,
    src: 'https://video.zhihu.com/video/1375448566316474369',
    link: 'https://www.zhihu.com/zvideo/1380855522980077568',
  },
  {
    title: '中国问答 70 年',
    desc: '时代在提问，中国在回答',
    content: [
      '在时代的提问中',
      '在中国的回答中',
      '是你 是我 是我们',
      '共同经历 见证的',
      '新中国 70 年。',
      '', // 空一行
      '谨以此片，献给新中国成立 70 年。',
    ],
    pic: card1,
    src: 'https://v.vzuu.com/video/1160348399378870272',
    link: 'https://zhuanlan.zhihu.com/p/37709620',
  },
  // {
  //   title: '职人介绍所',
  //   desc: '「我所在的职业，生活是什么样的」，每个普通人都能引起别人好奇的地方',
  //   content: `这个世界上有很多视频节目，有真人秀、有歌唱类，还有脱口秀。
  //   在普通人中有一个很明显的划分，叫做「职业」，职业和职业之间很有墙。这些无形的墙把不同职业的人圈在不同范围中，大家看起来生活在一起，但大家并不相互了解，甚至还会有很多误会。
  //   《职人介绍所》是一档职人向网络视频节目，希望能打破职业之间的墙，让观众们了解各行各业职人的生活。
  //   `,
  //   pic: card2,
  //   src: 'https://v.vzuu.com/video/929767007465869312',
  //   link: 'http://v.qq.com/detail/4/46274.html',
  // },
  {
    title: '2017 年, 什么在影响世界',
    desc: '2017 年，什么在影响世界？',
    content: `世界的走向，取决于大多数人的思考方式。
    我们不断发现思维的局限，探索认知的边界，不满足于表象，也不随波逐流。这个世界不够完美，总有痛苦需要承受。我们接受失败，但绝不停止探寻美好的脚步，因为思考可以影响世界，这个视频献给所有人，献给我们的世界，我们的 2017。
    `,
    pic: card3,
    src: 'https://v.vzuu.com/video/926560021154779136',
    link: 'https://www.zhihu.com/pin/926562615510073344',
  },
]

const renderContent = content => {
  if (Array.isArray(content)) {
    const html = content.join('<br>')
    return (
      <p className="card-content" dangerouslySetInnerHTML={{__html: html}} />
    )
  }

  return <p className="card-content">{content}</p>
}

export default class CardList extends Component {
  render() {
    return (
      <div className="card-list">
        {data.map(card => {
          const preTitle = card.preFixTitle ? card.preFixTitle : VAL_PRE_TITLE
          return (
            <div className="card" key={card.title}>
              <div className="card-picArea">
                <h2 className="card-title">
                  「{preTitle}」<small>{card.title}</small>
                </h2>
                <iframe
                  className="card-pic"
                  frameBorder="0"
                  width="559"
                  height="322"
                  src={`${card.src}?vzuu=true`}
                  allowFullScreen
                />
                {/* <img className="card-pic" src={card.pic} /> */}
              </div>
              <div className="card-block">
                <h3 className="card-desc">{card.desc}</h3>
                {renderContent(card.content)}
                {/* <a className="card-btn" href={card.link}>
                    <img className="card-btn-play" src={playIcon} />
                    <span>查看视频</span>
                  </a> */}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
