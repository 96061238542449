export function getCookie(cname) {
  const name = `${cname}=`
  const cookies = document.cookie.split(';')
  const cookieValue = ''
  for (let i = 0; i < cookies.length; i++) {
    let cookieItem = cookies[i]
    while (cookieItem.charAt(0) === ' ') {
      cookieItem = cookieItem.substring(1)
    }
    if (cookieItem.indexOf(name) === 0) {
      return cookieItem.substring(name.length, cookieItem.length)
    }
  }
  return cookieValue
}

export function setCookie(cname, cvalue, exdays = 1) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};domain=.vzuu.com;path=/`
}
