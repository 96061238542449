import React, {Component} from 'react'
import {chunk} from 'lodash'
import PropTypes from 'prop-types'
import Pagination from './Pagination'
import './VideoList.css'

export default class VideoList extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  }

  state = {
    items: [],
    current: 1,
    total: 0,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data === nextProps.data) return
    const {data, count} = nextProps
    this.setState({
      items: data.slice(0, count),
      total: Math.floor(data.length / count),
    })
  }

  componentDidMount() {
    const {data, count} = this.props
    this.setState({
      items: data.slice(0, count),
      total: Math.ceil(data.length / count),
    })
  }

  handlePage = index => {
    const {count, data} = this.props
    const {current} = this.state
    this.setState({
      current: index,
      items: data.slice(current * count, (current + 1) * count),
    })
  }

  renderItem(item, index) {
    const VideoRoot = 'https://v.vzuu.com/video/'
    const videoSrc = item.video_id ? `${VideoRoot}${item.video_id}` : item.src
    return (
      <div className="video-item" key={index}>
        <iframe
          frameBorder="0"
          width="344"
          height="212"
          src={`${videoSrc}?vzuu=true`}
          allowFullScreen
        />
        <p className="video-itemTitle">{item.title ? item.title : ''}</p>
        <p className="video-itemCount">
          {item.play_count ? `当日播放量 ${item.play_count}` : ''}
        </p>
        {this.props.id === 2 && (
          <div
            className="video-item-mask"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              window.alert('付费后方可观看，每条 2 元')
            }}
          />
        )}
      </div>
    )
  }

  render() {
    const {title} = this.props
    const {items, current, total} = this.state
    const groups = chunk(items, 3)
    return (
      <div className="VideoList">
        <h3 className="subtitle-page">{title}</h3>
        <div className="VideoList-content">
          {groups.map((group, index) => (
            <div className="video-group" key={index}>
              {group.map((item, itemIndex) => this.renderItem(item, itemIndex))}
            </div>
          ))}
        </div>
        {total > 1 && (
          <Pagination
            current={current}
            total={total}
            clickCallback={this.handlePage}
          />
        )}
      </div>
    )
  }
}
