import React, {Component} from 'react'
import cx from 'classnames'

export default class PaginationButton extends Component {
  handleClick = () => {
    const {clickCallback, page} = this.props
    clickCallback(page)
  }

  render() {
    const {text, isCurrent, className} = this.props
    return (
      <a
        className={cx(
          'PaginationButton',
          'Button',
          {'PaginationButton--current': isCurrent},
          className
        )}
        onClick={this.handleClick}
        disabled={isCurrent}
      >
        {text}
      </a>
    )
  }
}
