import React, {Component} from 'react'
import VideoList from './VideoList'
import jobVideo from '../config/job'
export default class Videos extends Component {
  genData = id => {
    const {videoSource} = this.props
    const start = parseInt(Math.random() * 80)
    const anyData = videoSource.slice(start + 10)

    switch (id) {
      case 5:
        return jobVideo.slice(0, 9)
      default:
        return anyData
    }
  }

  render() {
    const {id, title} = this.props
    return (
      <div className="title-page VideoPage">
        <h2 className="section-title">{title}</h2>
        <VideoList id={this.props.id} data={this.genData(id)} count={12} />
      </div>
    )
  }
}
