import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {chunk} from 'lodash'
import JobVideos from '../config/job'
import './HappyList.css'

const VideoRoot = 'https://v.vzuu.com/video/'

const HappyArray = [
  {name: '生活', id: 1},
  // {name: '新知（收费）', id: 2},
  {name: '人文', id: 3},
  {name: '娱乐', id: 4},
  // {name: '职人介绍所', id: 5},
]

function VideoCard(props) {
  const {src, title, count, happy} = props
  return (
    <div className="HomeVideoCard">
      <iframe
        frameBorder="0"
        width="344"
        height="212"
        src={`${src}?vzuu=true`}
        allowFullScreen
      />
      <p className="video-itemTitle">{title ? title : ''}</p>
      <p className="video-itemCount">{count ? `当日播放量 ${count}` : ''}</p>

      {happy === '新知（收费）' && (
        <div
          className="video-item-mask"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            window.alert('付费后方可观看，每条 2 元')
          }}
        />
      )}
    </div>
  )
}

export default class HappyList extends Component {
  static propTypes = {
    videoSource: PropTypes.array.isRequired,
  }

  state = {
    items: [],
    page: 0,
    arrowTo: 'right',
  }

  genRandomVideos = () => {
    const {videoSource} = this.props
    const randomStart = parseInt(Math.random() * 80)
    return videoSource.slice(randomStart, randomStart + 6)
  }

  randomVideosList(happy) {
    const videos =
      happy === '职人介绍所' ? JobVideos.slice(0, 6) : this.genRandomVideos()
    const groups = chunk(videos, 3)
    return (
      <div className="homeVideoList">
        {groups.map((videos, index) => (
          <div className="video-happy-group" key={index}>
            {videos.map(item => (
              <VideoCard
                id={this.props.id}
                key={item.video_id || item.src}
                count={item.play_count || 0}
                title={item.title}
                src={item.video_id ? VideoRoot + item.video_id : item.src}
                happy={happy}
              />
            ))}
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {handleTab} = this.props
    return (
      <div className="HappyList">
        {HappyArray.map((happy, index) => (
          <div className="happyItem" key={index}>
            <div className="happy-title-block">
              <h3 className="happy-title" onClick={() => handleTab(happy)}>
                {happy.name}
              </h3>
              <span className="happy-link" onClick={() => handleTab(happy)}>
                查看全部视频
              </span>
            </div>
            {this.randomVideosList(happy.name)}
          </div>
        ))}
      </div>
    )
  }
}
