const jobVideos = [
  {
    src: 'https://v.vzuu.com/video/929795440262553600',
    title: '为什么中国的学生不喜欢在课堂上提问？',
  },
  {
    src: 'https://v.vzuu.com/video/929805430327111680',
    title: '为什么有些人对猎头比较排斥？',
  },
  {
    src: 'https://v.vzuu.com/video/929800711579930624',
    title: '女生到底需要多少口红？',
  },
  {
    src: 'https://v.vzuu.com/video/929774474010587136',
    title: '艺人公司一般如何塑造艺人形象',
  },
  {
    src: 'https://v.vzuu.com/video/929774609155260416',
    title: '歌手艺人如何推广自己',
  },
  {
    src: 'https://v.vzuu.com/video/929774710124814336',
    title: '如何评价周杰伦新专辑《哎呦，不错哦》',
  },
  {
    src: 'https://v.vzuu.com/video/929774919554801664',
    title: '艺人被喜欢都是有原因的',
  },
  {
    src: 'https://v.vzuu.com/video/929776574165721088',
    title: '为什么近年来华语乐坛走向衰落',
  },
  {
    src: 'https://v.vzuu.com/video/929804701440983040',
    title: '柜台评价对柜员影响有多大',
  },
  {
    src: 'https://v.vzuu.com/video/929771103711236096',
    title: '开餐厅需要哪些要素？',
  },
  {
    src: 'https://v.vzuu.com/video/929804922489180160',
    title: '在银行工作是怎样的一种体验？',
  },
  {
    src: 'https://v.vzuu.com/video/929806528471425024',
    title: '华语乐坛有哪些特立独行的明星？',
  },
]

export default jobVideos
